<template>
  <div class="main-body">
    <div class="page-attention">
      <p>创建应用后系统会自动生成app_id、app_secret。</p>
    </div>
    <a-divider />
    <a-form :model="formState" autocomplete="off" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSubmit">
      <a-form-item label="应用名称" name="app_name" :rules="[{ required: true, message: '请输入应用名称!' }]">
        <a-input v-model:value="formState.app_name" placeholder="请输入应用名称" />
      </a-form-item>
      <a-form-item label="应用类型" name="app_type" :rules="[{ required: true, message: '请选择应用类型!' }]">
        <a-select v-model:value="formState.app_type">
          <a-select-option value="native">PC网页端</a-select-option>
          <a-select-option value="h5">手机网页端</a-select-option>
          <a-select-option value="pc_h5">响应式网站</a-select-option>
          <a-select-option value="wxchat">微信公众号</a-select-option>
          <a-select-option value="wxapp">微信小程序</a-select-option>
          <a-select-option value="alipayapp">支付宝小程序</a-select-option>
          <a-select-option value="uniapp">内部小程序</a-select-option>
          <a-select-option value="android">Android</a-select-option>
          <a-select-option value="ios">IOS</a-select-option>
          <a-select-option value="windows">windows桌面应用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="版本号名称" name="vsersion_name" :rules="[{ required: true, message: '请输入版本号名称!' }]" extra="如：1.0.0，升级时必须高于上一个版本设置的值">
        <a-input v-model:value="formState.vsersion_name" placeholder="请输入版本号" />
      </a-form-item>
      <a-form-item label="应用版本号" name="version" :rules="[{ required: true, message: '请输入应用版本号!' }]" extra="应用版本号，必须是整数，升级时必须高于上一次设置的值">
        <a-input-number v-model:value="formState.version" placeholder="请输入应用版本号" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" html-type="submit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { message } from "ant-design-vue";
import * as Api from "@/views/client/api";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const formState = reactive({
      app_name: "",
      app_type: "",
      vsersion_name: "1.0.0",
      version: "100",
    });

    const onSubmit = () => {
      Api.create(formState).then((res) => {
        if (res.status === 200) {
          message.success(res.message);
          router.push("/client/app/list");
        } else {
          message.error(res.message);
        }
      });
    };

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>